import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SVG from "react-inlinesvg"

import { Layout, Seo, SanityBlockContent } from "../components"
import { useSanity } from "../hooks"

export const query = graphql`
  query PostTemplateQ($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      _rawAuthor(resolveReferences: { maxDepth: 10 })
      _rawEditor(resolveReferences: { maxDepth: 10 })
      author {
        name
        image {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        slug {
          current
        }
      }
      date(formatString: "MMM DD, YYYY")
      description
      excerpt
      imageThumbnail {
        alt
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
        }
      }
      imageCover {
        alt
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
        }
      }
      schema {
        code
      }
      slug {
        current
      }
      title
    }
  }
`

const PostTemplate = ({ data }) => {
  const sanity = data.post

  return (
    <Layout>
      <Seo
        title={sanity?.title}
        description={sanity?.description}
        slug={`blog/${sanity?.slug?.current}`}
      >
        {sanity?.schema ? (
          <script type="application/ld+json">{`${sanity?.schema}`}</script>
        ) : null}
      </Seo>
      <article>
        <header className="relative overflow-hidden text-center bg-gradient-to-r from-gray-900 via-gray-600 to-gray-900">
          <div className="max-w-xl mx-auto px-6 lg:px-0">
            <div className="py-12 space-y-6">
              <h1 className="text-center text-accent-light">{sanity?.title}</h1>
              <div className="flex justify-center items-center gap-2 text-white text-xs sm:text-sm">
                {/* <GatsbyImage
                  image={sanity?.author.image?.asset?.gatsbyImageData}
                  className="w-8 h-8 object-cover rounded-full shadow-xl transition hover:scale-105"
                  alt={sanity?.image?.alt}
                  loading="lazy"
                />
                <p>by {sanity?.author.name}</p>
                <p className="text-accent font-bold">·</p> */}
                <p>{sanity?.date}</p>
              </div>
            </div>
            <figure className="relative mx-auto before:absolute before:bg-gray-100 before:h-[50%] before:left-[-100vw] before:right-[-100vw] before:bottom-0">
              {/* <figure className="relative mx-auto w-full h-full before:absolute before:bg-gray-100 before:h-[50%] before:left-[-100vw] before:right-[-100vw] before:bottom-0 before:z-10"> */}
              <GatsbyImage
                image={sanity?.imageCover?.asset?.gatsbyImageData}
                className="aspect-w-12 aspect-h-7 w-full h-full object-cover rounded-xl shadow"
                alt={sanity?.imageCover?.alt}
                loading="lazy"
              />
              {/* <SVG
                cacheRequests={true}
                description={sanity?.imageCover?.alt}
                loader={<span>Loading...</span>}
                onError={error => console.log(error.message)}
                onLoad={(src, hasCache) => console.log(src, hasCache)}
                src={sanity?.imageCover?.asset?.url}
                title={sanity?.imageCover?.alt}
                className="relative z-20 w-full h-full object-cover rounded-xl shadow"
              >
                <img
                  src={sanity?.imageCover?.asset?.url}
                  alt={sanity?.imageCover?.alt}
                />
              </SVG> */}
            </figure>
          </div>
        </header>
        <section>
          <div
            padding="none"
            className="bg-gray-100 shadow mx-auto space-y-16 pt-20"
          >
            <div className="max-w-xl mx-auto pb-24 px-6 lg:px-0">
              <SanityBlockContent
                blocks={sanity?._rawEditor}
                classes="prose-h2:text-gray-800 prose-h2:text-2xl prose-h3:text-gray-600 prose-h3:text-2xl prose-h4:text-gray-500 prose-h4:text-xl prose-a:text-accent hover:prose-a:text-accent-light"
              />
            </div>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default PostTemplate
